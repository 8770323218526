import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TranslatePageTitleStrategy extends TitleStrategy {
  private translateService = inject(TranslateService);
  private readonly title = inject(Title);

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.translateService.get(title).subscribe((translatedTitle) => {
        this.title.setTitle(translatedTitle + ' - Global Admin Panel');
      });
    } else {
      this.title.setTitle('Leafio Global Admin Panel');
    }
  }
}
