import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageScheme } from '@services/storage/storage-scheme.type';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  protected defaultView = inject(DOCUMENT).defaultView;
  protected localStoragePrefix = 'lf_gap_'; // leafio global admin panel

  protected getFullKeyName(key: keyof StorageScheme): string {
    return this.localStoragePrefix + key;
  }

  set<T extends keyof StorageScheme>(key: T, value: StorageScheme[T]): void {
    this.defaultView?.localStorage.setItem(this.getFullKeyName(key), JSON.stringify(value));
  }
  get<T extends keyof StorageScheme>(key: T): StorageScheme[T] | null {
    if (!this.defaultView) {
      return null;
    }
    const rawValue = this.defaultView.localStorage.getItem(this.getFullKeyName(key));

    if (rawValue === null) {
      return null;
    }

    try {
      return JSON.parse(rawValue) as StorageScheme[T];
    } catch (e) {
      return null;
    }
  }
  clear<T extends keyof StorageScheme>(key: T): void {
    this.defaultView?.localStorage.removeItem(this.getFullKeyName(key));
  }
}
