import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, importProvidersFrom, Provider } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { provideAnimations } from '@angular/platform-browser/animations';
import { LfScrollbarStrategyService } from '@leafio/ui/utils';
import { DEFAULT_APP_LOCALE } from '@constants/default-app-locale.const';
import { Router, TitleStrategy } from '@angular/router';
import { TranslatePageTitleStrategy } from '@utils/translate-page-title-strategy';
import { LfLoaderService } from '@leafio/ui/loader';
import { DialogModule } from '@angular/cdk/dialog';
import { UrlInterceptor } from '@interceptors/url.interceptor';
import { HeaderInterceptor } from '@interceptors/header.interceptor';
import { AuthService } from '@services/auth/auth.service';
import { AuthInterceptor } from '@interceptors/auth.interceptor';
import {
  LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG,
  LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG,
} from '@leafio/ui/dialog';
import { LF_PAGINATOR_CONFIG } from '@leafio/ui/paginator';
import { DEFAULT_TABLE_PER_PAGE } from '@constants/default-table-per-page.const';

const translateConfig = {
  defaultLanguage: DEFAULT_APP_LOCALE,
  loader: {
    provide: TranslateLoader,
    useFactory: (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json'),
    deps: [HttpClient],
  },
};

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
];

const INITIALIZERS = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AuthService, Router],
    useFactory: (service: AuthService, router: Router) => () => service.init(router),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [LfScrollbarStrategyService],
    useFactory: (service: LfScrollbarStrategyService) => () => service.use('auto'),
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [LfLoaderService],
    useFactory: (service: LfLoaderService) => () =>
      service.init({
        hidingDelay: 100,
      }),
  },
];

const PROVIDERS = [
  { provide: TitleStrategy, useClass: TranslatePageTitleStrategy },
  {
    provide: LF_CONFIRM_ACTION_DIALOG_DEFAULT_CONFIG,
    useValue: {
      cancelBtnText: 'buttons.cancel',
    },
  },
  {
    provide: LF_CONFIRMATION_DIALOG_DEFAULT_SECONDARY_BTN_CONFIG,
    useValue: {
      text: 'buttons.cancel',
    },
  },
  {
    provide: LF_PAGINATOR_CONFIG,
    useValue: {
      pageSize: DEFAULT_TABLE_PER_PAGE,
      zeroBasedIndexing: false,
    },
  },
];

export const CORE_PROVIDERS: (EnvironmentProviders | Provider)[] = [
  ...INTERCEPTORS,
  ...INITIALIZERS,
  ...PROVIDERS,
  importProvidersFrom(HttpClientModule),
  importProvidersFrom(TranslateModule.forRoot(translateConfig)),
  importProvidersFrom(DialogModule),
  provideAnimations(),
];
