import { inject, Injectable } from '@angular/core';
import { QUERY_PARAMS } from '@services/query-params/query-params.const';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { StorageService } from '@services/storage/storage.service';
import { SSO_CONFIG } from '@tokens/sso-config.token';
import { DOCUMENT } from '@angular/common';
import { StorageScheme } from '@services/storage/storage-scheme.type';
import { DEFAULT_APP_LOCALE } from '@constants/default-app-locale.const';
import { AppLocale } from '@type/app-locale';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefreshTokenResponse } from '@services/auth/auth.types';
import { Router } from '@angular/router';
import { ProfileService } from '@services/profile/profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  protected defaultView = inject(DOCUMENT).defaultView;
  protected http = inject(HttpClient);
  protected profileService = inject(ProfileService);

  protected queryParamsService = inject(QueryParamsService);
  protected storageService = inject(StorageService);
  protected ssoConfig = inject(SSO_CONFIG);

  protected lang: AppLocale = DEFAULT_APP_LOCALE;

  protected needToAutoNavigate = false;

  protected createState(): string | null {
    if (!this.defaultView || this.defaultView.location.pathname.length <= 1) {
      return null;
    }

    const pathname = this.defaultView.location.pathname;
    const id = btoa(pathname);
    const state: StorageScheme['oauth_state'] = {
      id,
      pathname,
    };
    this.storageService.set('oauth_state', state);

    return id;
  }

  protected getStatePathname(stateId: string): string | undefined {
    const state = this.storageService.get('oauth_state');

    if (state?.id === stateId) {
      return state.pathname;
    }

    return;
  }

  protected generateLoginUrl(): string {
    // https://account.leafio.cloud/login
    //    ?response_type=code
    //    &client_id=123
    //    &redirect_uri=http://admin-panel.leafio.cloud/admin/oauth/callback
    //    &lang=en
    //    &solution=global
    //    &state=123qwe
    const url = new URL(this.ssoConfig.url);

    url.searchParams.set('response_type', this.ssoConfig.response_type);
    url.searchParams.set('client_id', this.ssoConfig.client_id);
    url.searchParams.set('redirect_uri', this.ssoConfig.callback);
    url.searchParams.set('solution', this.ssoConfig.solution);
    url.searchParams.set('lang', this.lang);

    const state = this.createState();
    if (state) {
      url.searchParams.set('state', state);
    }

    return url.toString();
  }

  setLangForLogin(value: AppLocale) {
    this.lang = value;
  }

  protected readToken() {
    const token = this.queryParamsService.get(QUERY_PARAMS.ACCESS_TOKEN);
    if (token) {
      this.storageService.set('access_token', token);
      this.needToAutoNavigate = true;
    }
  }

  async checkAndAutoNavigate(router: Router) {
    if (!this.needToAutoNavigate) {
      return;
    }

    const state = this.queryParamsService.get(QUERY_PARAMS.STATE);
    let reloadPathname: string | undefined;

    if (state) {
      reloadPathname = this.getStatePathname(state);
      this.storageService.clear('oauth_state');
    }

    this.needToAutoNavigate = false;
    await router.navigateByUrl(reloadPathname || '/');
  }

  async init(router: Router) {
    this.readToken();
    await this.profileService.initialize();
    await this.checkAndAutoNavigate(router);
  }

  goToLogin() {
    this.storageService.clear('access_token');
    this.storageService.clear('oauth_state');

    this.defaultView?.location.replace(this.generateLoginUrl());
  }

  refreshToken(token: string): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(
      'admin/oauth/refresh_access_token',
      {},
      {
        params: {
          access_token: token,
        },
      },
    );
  }
}
