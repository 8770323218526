import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@acl/service/acl.service';

export const isRootRouteAvailableCanActivateFn: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const acl = inject(AclService);
  const router = inject(Router);
  const path = route.url[0]?.path;

  if (!acl.isUserSet()) {
    return router.createUrlTree(['/unauthorized']);
  }

  return acl.isAllowedRootRoute(path);
};
