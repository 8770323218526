export const environment = {
  production: false,
  urls: {
    api: 'https://dev.api.admin-panel.leafio.cloud/',
    assets: 'assets/',
    apiPrefixes: ['api/', 'admin/'],
  },
  sso: {
    url: 'https://dev.account.leafio.cloud/login',
    client_id: 'ulSOtzPi5DU9Si60VNTrj7gpp6W9fQHp7LtldRcCMbo',
    callback: 'https://dev.api.admin-panel.leafio.cloud/admin/oauth/callback',
    response_type: 'code',
    solution: 'global',
  },
};
