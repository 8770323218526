import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  protected defaultView = inject(DOCUMENT).defaultView;

  get params() {
    return new URLSearchParams(this.defaultView?.location.search || '');
  }

  has(name: string): boolean {
    return this.params.has(name);
  }

  get(name: string, fallback = ''): string {
    return this.has(name) ? this.params.get(name)! : fallback;
  }
}
