import { Component, computed, effect, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LfToastsOutlet } from '@leafio/ui/toasts';
import { LfMainMenuComponent, LfMenuItem } from '@leafio/ui/layout';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '@services/profile/profile.service';
import { AclService } from '@acl/service/acl.service';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LfToastsOutlet, LfMainMenuComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  allTopMenuItems: LfMenuItem[] = [
    {
      name: '',
      url: '/',
      iconName: 'menu/logo-leafio',
      className: 'lf-menu-item_with-leafio-logo',
    },
    {
      name: 'company.plural_title',
      url: 'companies',
      iconName: 'menu/work',
    },
    {
      name: 'internal_user.plural_title',
      url: 'internal-users',
      iconName: 'menu/users',
    },
    {
      name: 'user.plural_title',
      url: 'users',
      iconName: 'menu/users',
    },
  ];

  translate = inject(TranslateService);
  profileService = inject(ProfileService);
  acl = inject(AclService);
  authService = inject(AuthService);

  currentUser = computed(() => this.profileService.currentUser());
  locale = computed(() => this.profileService.locale());

  topMenuItems = computed<LfMenuItem[]>(() => {
    if (!this.currentUser()) {
      return [];
    }
    return this.allTopMenuItems.filter((item) => this.acl.isAllowedRootRoute(item.url || ''));
  });

  bottomMenuItems = computed<LfMenuItem[]>(() => {
    const user = this.currentUser();
    let profileSubheaderName = 'No User';
    const profileDescriptionParts = [];

    if (user) {
      profileSubheaderName = user.first_name + ' ' + user.last_name;
      if (user.email) {
        profileDescriptionParts.push(user.email);
      }
      if (user.username) {
        profileDescriptionParts.push(user.username);
      }
    }

    return [
      {
        name: 'profile.title',
        url: user ? 'profile' : null,
        iconName: 'menu/user',
        submenuHeader: {
          name: profileSubheaderName,
          description: profileDescriptionParts.join(' | '),
          url: user ? 'profile' : null,
        },
        children: [
          {
            name: 'profile.log_out',
            url: null,
            onClick: () => this.authService.goToLogin(),
            icon: 'log_out',
            accent: 'danger',
          },
        ],
      },
    ];
  });

  constructor() {
    effect(() => {
      this.translate.setDefaultLang(this.locale());
      this.translate.use(this.locale());
      this.authService.setLangForLogin(this.locale());
    });
  }
}
