import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { isRootRouteAvailableCanActivateFn } from '@guards/is-root-route-available.can-activate-fn';
import { defaultRouteResolverCanActivateFn } from '@guards/default-route-resolver.can-activate-fn';
import { CURRENT_COMPANY_TOKEN } from '@tokens/current-company.token';
import { signal } from '@angular/core';
import { Company } from '@type/company';
import { unauthorizedSectionGuard } from '@guards/unauthorized-section.can-activate-fn';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [defaultRouteResolverCanActivateFn],

    // this component should never be resolved
    // defaultRouteResolverCanActivateFn always will return UrlTree to redirect
    component: PageNotFoundComponent,
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/routes').then((r) => r.COMPANIES_ROUTES),
    canActivate: [isRootRouteAvailableCanActivateFn],
    title: 'company.plural_title',
  },
  {
    path: 'internal-users',
    loadChildren: () => import('./pages/internal-users/routes').then((r) => r.INTERNAL_USERS_ROUTES),
    canActivate: [isRootRouteAvailableCanActivateFn],
    title: 'internal_user.plural_title',
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/routes').then((r) => r.USERS_ROUTES),
    loadComponent: () => import('./pages/users/users.component').then((r) => r.UsersComponent),
    canActivate: [isRootRouteAvailableCanActivateFn],
    providers: [{ provide: CURRENT_COMPANY_TOKEN, useValue: signal<Company | null>(null) }],
    title: 'user.plural_title',
  },
  {
    path: 'profile',
    canActivate: [isRootRouteAvailableCanActivateFn],
    loadChildren: () => import('./pages/profile/routes').then((r) => r.PROFILE_ROUTES),
    title: 'profile.title',
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'unauthorized',
    canActivate: [unauthorizedSectionGuard],
    component: UnauthorizedComponent,
  },
  { path: '**', redirectTo: '/not-found' },
];
