import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { Observable, catchError, throwError, switchMap, take, filter } from 'rxjs';
import { StorageService } from '@services/storage/storage.service';
import { toObservable } from '@angular/core/rxjs-interop';

const TOKEN_EXPIRED_STATUS_CODE = 419;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  protected auth = inject(AuthService);
  protected storageService = inject(StorageService);

  protected tokenRefreshingInProgress = signal(false);
  protected tokenRefreshingObservable = toObservable(this.tokenRefreshingInProgress);

  protected waitForTokenRefreshed(clonedReq: HttpRequest<unknown>, next: HttpHandler) {
    return this.tokenRefreshingObservable.pipe(
      filter((inProgress) => !inProgress),
      take(1),
      switchMap(() => next.handle(clonedReq)),
    );
  }

  protected refreshToken(token: string, clonedReq: HttpRequest<unknown>, next: HttpHandler) {
    this.tokenRefreshingInProgress.set(true);

    return this.auth.refreshToken(token).pipe(
      switchMap((response) => {
        this.storageService.set('access_token', response.access_token);
        this.tokenRefreshingInProgress.set(false);
        return next.handle(clonedReq);
      }),
      catchError((error) => {
        this.auth.goToLogin();

        return throwError(() => error);
      }),
    );
  }

  protected processTokenExpiredError(error: HttpErrorResponse, clonedReq: HttpRequest<unknown>, next: HttpHandler) {
    const token = this.storageService.get('access_token');

    if (!token) {
      return this.processUnauthorizedError(error);
    }

    if (this.tokenRefreshingInProgress()) {
      return this.waitForTokenRefreshed(clonedReq, next);
    }

    return this.refreshToken(token, clonedReq, next);
  }

  protected processUnauthorizedError(error: HttpErrorResponse) {
    this.auth.goToLogin();
    return throwError(() => error);
  }

  protected processHttpError(error: HttpErrorResponse, clonedReq: HttpRequest<unknown>, next: HttpHandler) {
    if (error.status === TOKEN_EXPIRED_STATUS_CODE) {
      return this.processTokenExpiredError(error, clonedReq, next);
    }

    if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
      return this.processUnauthorizedError(error);
    }

    return throwError(() => error);
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloned = req.clone();

    if (cloned.url.includes('/api/') || cloned.url.includes('/admin/')) {
      return next.handle(req).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return this.processHttpError(error, cloned, next);
          }
          return throwError(() => error);
        }),
      );
    }
    return next.handle(req);
  }
}
