import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LfPlaceholder } from '@leafio/ui/placeholder';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [LfPlaceholder],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent {}
