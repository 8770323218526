import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ASSETS_URL } from '@tokens/assets-url.token';
import { StorageService } from '@services/storage/storage.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  protected assetsUrl = inject(ASSETS_URL);
  protected storageService = inject(StorageService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.assetsUrl)) return next.handle(req);

    const token = this.storageService.get('access_token');

    const headers = req.headers
      .set('Accept', req.headers.get('Accept') || 'application/json')
      .set('Authorization', `Bearer ${token}` || '');

    // for uploading files we dont need to set Content-Type header
    // if you change this, check users import functionality
    if (!(req.body instanceof FormData)) {
      headers.set('Content-Type', req.headers.get('Content-Type') || 'application/json');
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
